import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import { RadioInputGroup, RadioInput } from "@instructure/ui-forms";

import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import styles from "./question.module.css";
import {
  setCurrentModule,
  setCurrentStep,
  saveLocalStateToGlobalState
} from "../../../../state/actions";
import { func, shape, object } from "prop-types";
import QuestionHeader from "../../../../components/question-header";

class Question extends Component {
  state = this.props.savedState || {
    hasSubmitted: false,
    questionValue: null
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    saveLocalStateToGlobalState: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(1);
  }

  goForward = () => {
    if (!this.state.hasSubmitted) {
      this.setState(
        {
          hasSubmitted: true
        },
        () => {
          this.props.saveLocalStateToGlobalState(
            {
              questionValue: this.state.questionValue
            },
            {
              moduleId: 2,
              stepId: 1
            }
          );
        }
      );
    } else {
      navigate("/modules/travel/rights");
    }
  };

  goBack = () => {
    if (this.hasSubmitted) {
      this.setState(
        {
          hasSubmitted: false
        },
        () => {
          this.props.saveLocalStateToGlobalState(
            {
              questionValue: this.state.questionValue
            },
            {
              moduleId: 2,
              stepId: 1
            }
          );
        }
      );
    } else {
      navigate("/modules/travel/introduction/roger");
    }
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.openingText}>
            <p>
              You are excited to travel with your family and Question to Disney
              World.
            </p>
          </div>
          <div className={styles.imageArea}>
            <Img fluid={this.props.data.file.childImageSharp.fluid} />
          </div>
          <div className={styles.questionArea}>
            <RadioInputGroup
              name="q1"
              onChange={event => {
                this.setState(
                  {
                    questionValue: event.currentTarget.value
                  },
                  () => {
                    this.props.saveLocalStateToGlobalState(
                      {
                        questionValue: this.state.questionValue
                      },
                      {
                        moduleId: 2,
                        stepId: 1
                      }
                    );
                  }
                );
              }}
              value={this.state.questionValue}
              description={
                <QuestionHeader
                  icon={
                    <span
                      className={styles.emoji}
                      role="img"
                      aria-label="Thinking Face"
                    >
                      🤔
                    </span>
                  }
                >
                  <span>
                    Can you take Roger with you on the trip right now?
                  </span>
                </QuestionHeader>
              }
            >
              <RadioInput
                key="a1"
                label="Yes, Roger has been placed in my care and because I want him to feel like part of the family he can travel out of state with us to Disney World."
                value="a1"
              />
              <RadioInput
                key="a2"
                label="Yes, as a foster parent I have the right to decide in which activities the child in my care can participate."
                value="a2"
              />
              <RadioInput
                key="a3"
                label="No, out of state travel decision requires the permission of the biological parent or the court."
                value="a3"
              />
              <RadioInput
                key="a4"
                label="No, normalcy laws state that out of state decisions must be made by the Department of Child and Family Services."
                value="a4"
              />
            </RadioInputGroup>
            {this.state.hasSubmitted && (
              <div className={styles.feedbackBlurb}>
                Now that you have chosen an answer, let's learn about the
                process to determine if you were right.
              </div>
            )}
          </div>

          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  savedState: state.modules.find(x => x.id === 2).steps.find(x => x.id === 1)
    .localState,
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    },
    saveLocalStateToGlobalState(state, info) {
      dispatch(saveLocalStateToGlobalState(state, info));
    }
  };
};

export const query = graphql`
  query {
    file(name: { eq: "family" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default connect(
  stateToProps,
  dispatchToProps
)(Question);
